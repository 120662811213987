@import "./assets/scss/_config.scss";
/*
 * @deprecated
 * Dit bestand is apart opgezet waarmee het mogelijk is geworden om bootstrap (langzaam) uit te fasereren.
 * De bedoeling is om de komende periode steeds meer @import's eruit te halen en vervangen door Vue(tify) Compononenten/CSS.
*/

/*!
 * Bootstrap v4.0.0-alpha.5 (https://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Boostrap settings:
$card-border-radius: 0;
$font-family-base: 'Poppins', sans-serif;

// Core variables and mixins
//@import "~bootstrap/scss/custom";
//@import "~bootstrap/scss/variables";
@import "./variables22";
@import "~bootstrap/scss/mixins";

// Reset and dependencies
@import "~bootstrap/scss/normalize";
@import "~bootstrap/scss/print";

// Core CSS
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

// Components
@import "~bootstrap/scss/animation";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/tags";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/responsive-embed";
@import "~bootstrap/scss/close";

// Components w/ JavaScript
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";

// Utility classes
@import "~bootstrap/scss/utilities";

.card {
  font-size: 1.066rem !important;
}

@media (min-width: 1400px) {
  .container {
    width: 1360px;
    max-width: 100%;
  }
}
