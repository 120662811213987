@function fa-icon($value) {
  @return unquote('"\\#{$value}"');
}

.#{$fa-css-prefix}-glass:before { content: fa-icon($fa-var-glass); }
.#{$fa-css-prefix}-music:before { content: fa-icon($fa-var-music); }
.#{$fa-css-prefix}-search:before { content: fa-icon($fa-var-search); }
.#{$fa-css-prefix}-envelope-o:before { content: fa-icon($fa-var-envelope-o); }
.#{$fa-css-prefix}-heart:before { content: fa-icon($fa-var-heart); }
.#{$fa-css-prefix}-star:before { content: fa-icon($fa-var-star); }
.#{$fa-css-prefix}-star-o:before { content: fa-icon($fa-var-star-o); }
.#{$fa-css-prefix}-user:before { content: fa-icon($fa-var-user); }
.#{$fa-css-prefix}-film:before { content: fa-icon($fa-var-film); }
.#{$fa-css-prefix}-th-large:before { content: fa-icon($fa-var-th-large); }
.#{$fa-css-prefix}-th:before { content: fa-icon($fa-var-th); }
.#{$fa-css-prefix}-th-list:before { content: fa-icon($fa-var-th-list); }
.#{$fa-css-prefix}-check:before { content: fa-icon($fa-var-check); }
.#{$fa-css-prefix}-remove:before,
.#{$fa-css-prefix}-close:before,
.#{$fa-css-prefix}-times:before { content: fa-icon($fa-var-times); }
.#{$fa-css-prefix}-search-plus:before { content: fa-icon($fa-var-search-plus); }
.#{$fa-css-prefix}-search-minus:before { content: fa-icon($fa-var-search-minus); }
.#{$fa-css-prefix}-power-off:before { content: fa-icon($fa-var-power-off); }
.#{$fa-css-prefix}-signal:before { content: fa-icon($fa-var-signal); }
.#{$fa-css-prefix}-gear:before,
.#{$fa-css-prefix}-cog:before { content: fa-icon($fa-var-cog); }
.#{$fa-css-prefix}-trash-o:before { content: fa-icon($fa-var-trash-o); }
.#{$fa-css-prefix}-home:before { content: fa-icon($fa-var-home); }
.#{$fa-css-prefix}-file-o:before { content: fa-icon($fa-var-file-o); }
.#{$fa-css-prefix}-clock-o:before { content: fa-icon($fa-var-clock-o); }
.#{$fa-css-prefix}-road:before { content: fa-icon($fa-var-road); }
.#{$fa-css-prefix}-download:before { content: fa-icon($fa-var-download); }
.#{$fa-css-prefix}-arrow-circle-o-down:before { content: fa-icon($fa-var-arrow-circle-o-down); }
.#{$fa-css-prefix}-arrow-circle-o-up:before { content: fa-icon($fa-var-arrow-circle-o-up); }
.#{$fa-css-prefix}-inbox:before { content: fa-icon($fa-var-inbox); }
.#{$fa-css-prefix}-play-circle-o:before { content: fa-icon($fa-var-play-circle-o); }
.#{$fa-css-prefix}-rotate-right:before,
.#{$fa-css-prefix}-repeat:before { content: fa-icon($fa-var-repeat); }
.#{$fa-css-prefix}-refresh:before { content: fa-icon($fa-var-refresh); }
.#{$fa-css-prefix}-list-alt:before { content: fa-icon($fa-var-list-alt); }
.#{$fa-css-prefix}-lock:before { content: fa-icon($fa-var-lock); }
.#{$fa-css-prefix}-flag:before { content: fa-icon($fa-var-flag); }
.#{$fa-css-prefix}-headphones:before { content: fa-icon($fa-var-headphones); }
.#{$fa-css-prefix}-volume-off:before { content: fa-icon($fa-var-volume-off); }
.#{$fa-css-prefix}-volume-down:before { content: fa-icon($fa-var-volume-down); }
.#{$fa-css-prefix}-volume-up:before { content: fa-icon($fa-var-volume-up); }
.#{$fa-css-prefix}-qrcode:before { content: fa-icon($fa-var-qrcode); }
.#{$fa-css-prefix}-barcode:before { content: fa-icon($fa-var-barcode); }
.#{$fa-css-prefix}-tag:before { content: fa-icon($fa-var-tag); }
.#{$fa-css-prefix}-tags:before { content: fa-icon($fa-var-tags); }
.#{$fa-css-prefix}-book:before { content: fa-icon($fa-var-book); }
.#{$fa-css-prefix}-bookmark:before { content: fa-icon($fa-var-bookmark); }
.#{$fa-css-prefix}-print:before { content: fa-icon($fa-var-print); }
.#{$fa-css-prefix}-camera:before { content: fa-icon($fa-var-camera); }
.#{$fa-css-prefix}-font:before { content: fa-icon($fa-var-font); }
.#{$fa-css-prefix}-bold:before { content: fa-icon($fa-var-bold); }
.#{$fa-css-prefix}-italic:before { content: fa-icon($fa-var-italic); }
.#{$fa-css-prefix}-text-height:before { content: fa-icon($fa-var-text-height); }
.#{$fa-css-prefix}-text-width:before { content: fa-icon($fa-var-text-width); }
.#{$fa-css-prefix}-align-left:before { content: fa-icon($fa-var-align-left); }
.#{$fa-css-prefix}-align-center:before { content: fa-icon($fa-var-align-center); }
.#{$fa-css-prefix}-align-right:before { content: fa-icon($fa-var-align-right); }
.#{$fa-css-prefix}-align-justify:before { content: fa-icon($fa-var-align-justify); }
.#{$fa-css-prefix}-list:before { content: fa-icon($fa-var-list); }
.#{$fa-css-prefix}-dedent:before,
.#{$fa-css-prefix}-outdent:before { content: fa-icon($fa-var-outdent); }
.#{$fa-css-prefix}-indent:before { content: fa-icon($fa-var-indent); }
.#{$fa-css-prefix}-video-camera:before { content: fa-icon($fa-var-video-camera); }
.#{$fa-css-prefix}-photo:before,
.#{$fa-css-prefix}-image:before,
.#{$fa-css-prefix}-picture-o:before { content: fa-icon($fa-var-picture-o); }
.#{$fa-css-prefix}-pencil:before { content: fa-icon($fa-var-pencil); }
.#{$fa-css-prefix}-map-marker:before { content: fa-icon($fa-var-map-marker); }
.#{$fa-css-prefix}-adjust:before { content: fa-icon($fa-var-adjust); }
.#{$fa-css-prefix}-tint:before { content: fa-icon($fa-var-tint); }
.#{$fa-css-prefix}-edit:before,
.#{$fa-css-prefix}-pencil-square-o:before { content: fa-icon($fa-var-pencil-square-o); }
.#{$fa-css-prefix}-share-square-o:before { content: fa-icon($fa-var-share-square-o); }
.#{$fa-css-prefix}-check-square-o:before { content: fa-icon($fa-var-check-square-o); }
.#{$fa-css-prefix}-arrows:before { content: fa-icon($fa-var-arrows); }
.#{$fa-css-prefix}-step-backward:before { content: fa-icon($fa-var-step-backward); }
.#{$fa-css-prefix}-fast-backward:before { content: fa-icon($fa-var-fast-backward); }
.#{$fa-css-prefix}-backward:before { content: fa-icon($fa-var-backward); }
.#{$fa-css-prefix}-play:before { content: fa-icon($fa-var-play); }
.#{$fa-css-prefix}-pause:before { content: fa-icon($fa-var-pause); }
.#{$fa-css-prefix}-stop:before { content: fa-icon($fa-var-stop); }
.#{$fa-css-prefix}-forward:before { content: fa-icon($fa-var-forward); }
.#{$fa-css-prefix}-fast-forward:before { content: fa-icon($fa-var-fast-forward); }
.#{$fa-css-prefix}-step-forward:before { content: fa-icon($fa-var-step-forward); }
.#{$fa-css-prefix}-eject:before { content: fa-icon($fa-var-eject); }
.#{$fa-css-prefix}-chevron-left:before { content: fa-icon($fa-var-chevron-left); }
.#{$fa-css-prefix}-chevron-right:before { content: fa-icon($fa-var-chevron-right); }
.#{$fa-css-prefix}-plus-circle:before { content: fa-icon($fa-var-plus-circle); }
.#{$fa-css-prefix}-minus-circle:before { content: fa-icon($fa-var-minus-circle); }
.#{$fa-css-prefix}-times-circle:before { content: fa-icon($fa-var-times-circle); }
.#{$fa-css-prefix}-check-circle:before { content: fa-icon($fa-var-check-circle); }
.#{$fa-css-prefix}-question-circle:before { content: fa-icon($fa-var-question-circle); }
.#{$fa-css-prefix}-info-circle:before { content: fa-icon($fa-var-info-circle); }
.#{$fa-css-prefix}-crosshairs:before { content: fa-icon($fa-var-crosshairs); }
.#{$fa-css-prefix}-times-circle-o:before { content: fa-icon($fa-var-times-circle-o); }
.#{$fa-css-prefix}-check-circle-o:before { content: fa-icon($fa-var-check-circle-o); }
.#{$fa-css-prefix}-ban:before { content: fa-icon($fa-var-ban); }
.#{$fa-css-prefix}-arrow-left:before { content: fa-icon($fa-var-arrow-left); }
.#{$fa-css-prefix}-arrow-right:before { content: fa-icon($fa-var-arrow-right); }
.#{$fa-css-prefix}-arrow-up:before { content: fa-icon($fa-var-arrow-up); }
.#{$fa-css-prefix}-arrow-down:before { content: fa-icon($fa-var-arrow-down); }
.#{$fa-css-prefix}-mail-forward:before,
.#{$fa-css-prefix}-share:before { content: fa-icon($fa-var-share); }
.#{$fa-css-prefix}-expand:before { content: fa-icon($fa-var-expand); }
.#{$fa-css-prefix}-compress:before { content: fa-icon($fa-var-compress); }
.#{$fa-css-prefix}-plus:before { content: fa-icon($fa-var-plus); }
.#{$fa-css-prefix}-minus:before { content: fa-icon($fa-var-minus); }
.#{$fa-css-prefix}-asterisk:before { content: fa-icon($fa-var-asterisk); }
.#{$fa-css-prefix}-exclamation-circle:before { content: fa-icon($fa-var-exclamation-circle); }
.#{$fa-css-prefix}-gift:before { content: fa-icon($fa-var-gift); }
.#{$fa-css-prefix}-leaf:before { content: fa-icon($fa-var-leaf); }
.#{$fa-css-prefix}-fire:before { content: fa-icon($fa-var-fire); }
.#{$fa-css-prefix}-eye:before { content: fa-icon($fa-var-eye); }
.#{$fa-css-prefix}-eye-slash:before { content: fa-icon($fa-var-eye-slash); }
.#{$fa-css-prefix}-warning:before,
.#{$fa-css-prefix}-exclamation-triangle:before { content: fa-icon($fa-var-exclamation-triangle); }
.#{$fa-css-prefix}-plane:before { content: fa-icon($fa-var-plane); }
.#{$fa-css-prefix}-calendar:before { content: fa-icon($fa-var-calendar); }
.#{$fa-css-prefix}-random:before { content: fa-icon($fa-var-random); }
.#{$fa-css-prefix}-comment:before { content: fa-icon($fa-var-comment); }
.#{$fa-css-prefix}-magnet:before { content: fa-icon($fa-var-magnet); }
.#{$fa-css-prefix}-chevron-up:before { content: fa-icon($fa-var-chevron-up); }
.#{$fa-css-prefix}-chevron-down:before { content: fa-icon($fa-var-chevron-down); }
.#{$fa-css-prefix}-retweet:before { content: fa-icon($fa-var-retweet); }
.#{$fa-css-prefix}-shopping-cart:before { content: fa-icon($fa-var-shopping-cart); }
.#{$fa-css-prefix}-folder:before { content: fa-icon($fa-var-folder); }
.#{$fa-css-prefix}-folder-open:before { content: fa-icon($fa-var-folder-open); }
.#{$fa-css-prefix}-arrows-v:before { content: fa-icon($fa-var-arrows-v); }
.#{$fa-css-prefix}-arrows-h:before { content: fa-icon($fa-var-arrows-h); }
.#{$fa-css-prefix}-bar-chart-o:before,
.#{$fa-css-prefix}-bar-chart:before { content: fa-icon($fa-var-bar-chart); }
.#{$fa-css-prefix}-camera-retro:before { content: fa-icon($fa-var-camera-retro); }
.#{$fa-css-prefix}-key:before { content: fa-icon($fa-var-key); }
.#{$fa-css-prefix}-gears:before,
.#{$fa-css-prefix}-cogs:before { content: fa-icon($fa-var-cogs); }
.#{$fa-css-prefix}-comments:before { content: fa-icon($fa-var-comments); }
.#{$fa-css-prefix}-thumbs-o-up:before { content: fa-icon($fa-var-thumbs-o-up); }
.#{$fa-css-prefix}-thumbs-o-down:before { content: fa-icon($fa-var-thumbs-o-down); }
.#{$fa-css-prefix}-star-half:before { content: fa-icon($fa-var-star-half); }
.#{$fa-css-prefix}-heart-o:before { content: fa-icon($fa-var-heart-o); }
.#{$fa-css-prefix}-sign-out:before { content: fa-icon($fa-var-sign-out); }
.#{$fa-css-prefix}-thumb-tack:before { content: fa-icon($fa-var-thumb-tack); }
.#{$fa-css-prefix}-external-link:before { content: fa-icon($fa-var-external-link); }
.#{$fa-css-prefix}-sign-in:before { content: fa-icon($fa-var-sign-in); }
.#{$fa-css-prefix}-trophy:before { content: fa-icon($fa-var-trophy); }
.#{$fa-css-prefix}-upload:before { content: fa-icon($fa-var-upload); }
.#{$fa-css-prefix}-lemon-o:before { content: fa-icon($fa-var-lemon-o); }
.#{$fa-css-prefix}-phone:before { content: fa-icon($fa-var-phone); }
.#{$fa-css-prefix}-square-o:before { content: fa-icon($fa-var-square-o); }
.#{$fa-css-prefix}-bookmark-o:before { content: fa-icon($fa-var-bookmark-o); }
.#{$fa-css-prefix}-phone-square:before { content: fa-icon($fa-var-phone-square); }
.#{$fa-css-prefix}-unlock:before { content: fa-icon($fa-var-unlock); }
.#{$fa-css-prefix}-credit-card:before { content: fa-icon($fa-var-credit-card); }
.#{$fa-css-prefix}-feed:before,
.#{$fa-css-prefix}-hdd-o:before { content: fa-icon($fa-var-hdd-o); }
.#{$fa-css-prefix}-bullhorn:before { content: fa-icon($fa-var-bullhorn); }
.#{$fa-css-prefix}-bell:before { content: fa-icon($fa-var-bell); }
.#{$fa-css-prefix}-certificate:before { content: fa-icon($fa-var-certificate); }
.#{$fa-css-prefix}-hand-o-right:before { content: fa-icon($fa-var-hand-o-right); }
.#{$fa-css-prefix}-hand-o-left:before { content: fa-icon($fa-var-hand-o-left); }
.#{$fa-css-prefix}-hand-o-up:before { content: fa-icon($fa-var-hand-o-up); }
.#{$fa-css-prefix}-hand-o-down:before { content: fa-icon($fa-var-hand-o-down); }
.#{$fa-css-prefix}-arrow-circle-left:before { content: fa-icon($fa-var-arrow-circle-left); }
.#{$fa-css-prefix}-arrow-circle-right:before { content: fa-icon($fa-var-arrow-circle-right); }
.#{$fa-css-prefix}-arrow-circle-up:before { content: fa-icon($fa-var-arrow-circle-up); }
.#{$fa-css-prefix}-arrow-circle-down:before { content: fa-icon($fa-var-arrow-circle-down); }
.#{$fa-css-prefix}-globe:before { content: fa-icon($fa-var-globe); }
.#{$fa-css-prefix}-wrench:before { content: fa-icon($fa-var-wrench); }
.#{$fa-css-prefix}-tasks:before { content: fa-icon($fa-var-tasks); }
.#{$fa-css-prefix}-filter:before { content: fa-icon($fa-var-filter); }
.#{$fa-css-prefix}-briefcase:before { content: fa-icon($fa-var-briefcase); }
.#{$fa-css-prefix}-arrows-alt:before { content: fa-icon($fa-var-arrows-alt); }
.#{$fa-css-prefix}-group:before,
.#{$fa-css-prefix}-users:before { content: fa-icon($fa-var-users); }
.#{$fa-css-prefix}-chain:before,
.#{$fa-css-prefix}-link:before { content: fa-icon($fa-var-link); }
.#{$fa-css-prefix}-cloud:before { content: fa-icon($fa-var-cloud); }
.#{$fa-css-prefix}-flask:before { content: fa-icon($fa-var-flask); }
.#{$fa-css-prefix}-cut:before,
.#{$fa-css-prefix}-scissors:before { content: fa-icon($fa-var-scissors); }
.#{$fa-css-prefix}-copy:before,
.#{$fa-css-prefix}-files-o:before { content: fa-icon($fa-var-files-o); }
.#{$fa-css-prefix}-paperclip:before { content: fa-icon($fa-var-paperclip); }
.#{$fa-css-prefix}-save:before,
.#{$fa-css-prefix}-floppy-o:before { content: fa-icon($fa-var-floppy-o); }
.#{$fa-css-prefix}-square:before { content: fa-icon($fa-var-square); }
.#{$fa-css-prefix}-navicon:before,
.#{$fa-css-prefix}-reorder:before,
.#{$fa-css-prefix}-bars:before { content: fa-icon($fa-var-bars); }
.#{$fa-css-prefix}-list-ul:before { content: fa-icon($fa-var-list-ul); }
.#{$fa-css-prefix}-list-ol:before { content: fa-icon($fa-var-list-ol); }
.#{$fa-css-prefix}-strikethrough:before { content: fa-icon($fa-var-strikethrough); }
.#{$fa-css-prefix}-underline:before { content: fa-icon($fa-var-underline); }
.#{$fa-css-prefix}-table:before { content: fa-icon($fa-var-table); }
.#{$fa-css-prefix}-magic:before { content: fa-icon($fa-var-magic); }
.#{$fa-css-prefix}-truck:before { content: fa-icon($fa-var-truck); }
.#{$fa-css-prefix}-money:before { content: fa-icon($fa-var-money); }
.#{$fa-css-prefix}-caret-down:before { content: fa-icon($fa-var-caret-down); }
.#{$fa-css-prefix}-caret-up:before { content: fa-icon($fa-var-caret-up); }
.#{$fa-css-prefix}-caret-left:before { content: fa-icon($fa-var-caret-left); }
.#{$fa-css-prefix}-caret-right:before { content: fa-icon($fa-var-caret-right); }
.#{$fa-css-prefix}-columns:before { content: fa-icon($fa-var-columns); }
.#{$fa-css-prefix}-unsorted:before,
.#{$fa-css-prefix}-sort:before { content: fa-icon($fa-var-sort); }
.#{$fa-css-prefix}-sort-down:before,
.#{$fa-css-prefix}-sort-desc:before { content: fa-icon($fa-var-sort-desc); }
.#{$fa-css-prefix}-sort-up:before,
.#{$fa-css-prefix}-sort-asc:before { content: fa-icon($fa-var-sort-asc); }
.#{$fa-css-prefix}-envelope:before { content: fa-icon($fa-var-envelope); }
.#{$fa-css-prefix}-rotate-left:before,
.#{$fa-css-prefix}-undo:before { content: fa-icon($fa-var-undo); }
.#{$fa-css-prefix}-legal:before,
.#{$fa-css-prefix}-gavel:before { content: fa-icon($fa-var-gavel); }
.#{$fa-css-prefix}-dashboard:before,
.#{$fa-css-prefix}-tachometer:before { content: fa-icon($fa-var-tachometer); }
.#{$fa-css-prefix}-comment-o:before { content: fa-icon($fa-var-comment-o); }
.#{$fa-css-prefix}-comments-o:before { content: fa-icon($fa-var-comments-o); }
.#{$fa-css-prefix}-flash:before,
.#{$fa-css-prefix}-bolt:before { content: fa-icon($fa-var-bolt); }
.#{$fa-css-prefix}-sitemap:before { content: fa-icon($fa-var-sitemap); }
.#{$fa-css-prefix}-umbrella:before { content: fa-icon($fa-var-umbrella); }
.#{$fa-css-prefix}-paste:before,
.#{$fa-css-prefix}-clipboard:before { content: fa-icon($fa-var-clipboard); }
.#{$fa-css-prefix}-lightbulb-o:before { content: fa-icon($fa-var-lightbulb-o); }
.#{$fa-css-prefix}-exchange:before { content: fa-icon($fa-var-exchange); }
.#{$fa-css-prefix}-cloud-download:before { content: fa-icon($fa-var-cloud-download); }
.#{$fa-css-prefix}-cloud-upload:before { content: fa-icon($fa-var-cloud-upload); }
.#{$fa-css-prefix}-user-md:before { content: fa-icon($fa-var-user-md); }
.#{$fa-css-prefix}-stethoscope:before { content: fa-icon($fa-var-stethoscope); }
.#{$fa-css-prefix}-suitcase:before { content: fa-icon($fa-var-suitcase); }
.#{$fa-css-prefix}-bell-o:before { content: fa-icon($fa-var-bell-o); }
.#{$fa-css-prefix}-coffee:before { content: fa-icon($fa-var-coffee); }
.#{$fa-css-prefix}-cutlery:before { content: fa-icon($fa-var-cutlery); }
.#{$fa-css-prefix}-file-text-o:before { content: fa-icon($fa-var-file-text-o); }
.#{$fa-css-prefix}-building-o:before { content: fa-icon($fa-var-building-o); }
.#{$fa-css-prefix}-hospital-o:before { content: fa-icon($fa-var-hospital-o); }
.#{$fa-css-prefix}-ambulance:before { content: fa-icon($fa-var-ambulance); }
.#{$fa-css-prefix}-medkit:before { content: fa-icon($fa-var-medkit); }
.#{$fa-css-prefix}-fighter-jet:before { content: fa-icon($fa-var-fighter-jet); }
.#{$fa-css-prefix}-beer:before { content: fa-icon($fa-var-beer); }
.#{$fa-css-prefix}-h-square:before { content: fa-icon($fa-var-h-square); }
.#{$fa-css-prefix}-plus-square:before { content: fa-icon($fa-var-plus-square); }
.#{$fa-css-prefix}-angle-double-left:before { content: fa-icon($fa-var-angle-double-left); }
.#{$fa-css-prefix}-angle-double-right:before { content: fa-icon($fa-var-angle-double-right); }
.#{$fa-css-prefix}-angle-double-up:before { content: fa-icon($fa-var-angle-double-up); }
.#{$fa-css-prefix}-angle-double-down:before { content: fa-icon($fa-var-angle-double-down); }
.#{$fa-css-prefix}-angle-left:before { content: fa-icon($fa-var-angle-left); }
.#{$fa-css-prefix}-angle-right:before { content: fa-icon($fa-var-angle-right); }
.#{$fa-css-prefix}-angle-up:before { content: fa-icon($fa-var-angle-up); }
.#{$fa-css-prefix}-angle-down:before { content: fa-icon($fa-var-angle-down); }
.#{$fa-css-prefix}-desktop:before { content: fa-icon($fa-var-desktop); }
.#{$fa-css-prefix}-laptop:before { content: fa-icon($fa-var-laptop); }
.#{$fa-css-prefix}-tablet:before { content: fa-icon($fa-var-tablet); }
.#{$fa-css-prefix}-mobile-phone:before,
.#{$fa-css-prefix}-mobile:before { content: fa-icon($fa-var-mobile); }
.#{$fa-css-prefix}-circle-o:before { content: fa-icon($fa-var-circle-o); }
.#{$fa-css-prefix}-quote-left:before { content: fa-icon($fa-var-quote-left); }
.#{$fa-css-prefix}-quote-right:before { content: fa-icon($fa-var-quote-right); }
.#{$fa-css-prefix}-spinner:before { content: fa-icon($fa-var-spinner); }
.#{$fa-css-prefix}-circle:before { content: fa-icon($fa-var-circle); }
.#{$fa-css-prefix}-mail-reply:before,
.#{$fa-css-prefix}-reply:before { content: fa-icon($fa-var-reply); }
.#{$fa-css-prefix}-folder-o:before { content: fa-icon($fa-var-folder-o); }
.#{$fa-css-prefix}-folder-open-o:before { content: fa-icon($fa-var-folder-open-o); }
.#{$fa-css-prefix}-smile-o:before { content: fa-icon($fa-var-smile-o); }
.#{$fa-css-prefix}-frown-o:before { content: fa-icon($fa-var-frown-o); }
.#{$fa-css-prefix}-meh-o:before { content: fa-icon($fa-var-meh-o); }
.#{$fa-css-prefix}-gamepad:before { content: fa-icon($fa-var-gamepad); }
.#{$fa-css-prefix}-keyboard-o:before { content: fa-icon($fa-var-keyboard-o); }
.#{$fa-css-prefix}-flag-o:before { content: fa-icon($fa-var-flag-o); }
.#{$fa-css-prefix}-flag-checkered:before { content: fa-icon($fa-var-flag-checkered); }
.#{$fa-css-prefix}-terminal:before { content: fa-icon($fa-var-terminal); }
.#{$fa-css-prefix}-code:before { content: fa-icon($fa-var-code); }
.#{$fa-css-prefix}-mail-reply-all:before,
.#{$fa-css-prefix}-reply-all:before { content: fa-icon($fa-var-reply-all); }
.#{$fa-css-prefix}-star-half-empty:before,
.#{$fa-css-prefix}-star-half-full:before,
.#{$fa-css-prefix}-star-half-o:before { content: fa-icon($fa-var-star-half-o); }
.#{$fa-css-prefix}-location-arrow:before { content: fa-icon($fa-var-location-arrow); }
.#{$fa-css-prefix}-crop:before { content: fa-icon($fa-var-crop); }
.#{$fa-css-prefix}-code-fork:before { content: fa-icon($fa-var-code-fork); }
.#{$fa-css-prefix}-unlink:before,
.#{$fa-css-prefix}-chain-broken:before { content: fa-icon($fa-var-chain-broken); }
.#{$fa-css-prefix}-question:before { content: fa-icon($fa-var-question); }
.#{$fa-css-prefix}-info:before { content: fa-icon($fa-var-info); }
.#{$fa-css-prefix}-exclamation:before { content: fa-icon($fa-var-exclamation); }
.#{$fa-css-prefix}-superscript:before { content: fa-icon($fa-var-superscript); }
.#{$fa-css-prefix}-subscript:before { content: fa-icon($fa-var-subscript); }
.#{$fa-css-prefix}-eraser:before { content: fa-icon($fa-var-eraser); }
.#{$fa-css-prefix}-puzzle-piece:before { content: fa-icon($fa-var-puzzle-piece); }
.#{$fa-css-prefix}-microphone:before { content: fa-icon($fa-var-microphone); }
.#{$fa-css-prefix}-microphone-slash:before { content: fa-icon($fa-var-microphone-slash); }
.#{$fa-css-prefix}-shield:before { content: fa-icon($fa-var-shield); }
.#{$fa-css-prefix}-calendar-o:before { content: fa-icon($fa-var-calendar-o); }
.#{$fa-css-prefix}-fire-extinguisher:before { content: fa-icon($fa-var-fire-extinguisher); }
.#{$fa-css-prefix}-rocket:before { content: fa-icon($fa-var-rocket); }
.#{$fa-css-prefix}-maxcdn:before { content: fa-icon($fa-var-maxcdn); }
.#{$fa-css-prefix}-chevron-circle-left:before { content: fa-icon($fa-var-chevron-circle-left); }
.#{$fa-css-prefix}-chevron-circle-right:before { content: fa-icon($fa-var-chevron-circle-right); }
.#{$fa-css-prefix}-chevron-circle-up:before { content: fa-icon($fa-var-chevron-circle-up); }
.#{$fa-css-prefix}-chevron-circle-down:before { content: fa-icon($fa-var-chevron-circle-down); }
.#{$fa-css-prefix}-anchor:before { content: fa-icon($fa-var-anchor); }
.#{$fa-css-prefix}-unlock-alt:before { content: fa-icon($fa-var-unlock-alt); }
.#{$fa-css-prefix}-bullseye:before { content: fa-icon($fa-var-bullseye); }
.#{$fa-css-prefix}-ellipsis-h:before { content: fa-icon($fa-var-ellipsis-h); }
.#{$fa-css-prefix}-ellipsis-v:before { content: fa-icon($fa-var-ellipsis-v); }
.#{$fa-css-prefix}-play-circle:before { content: fa-icon($fa-var-play-circle); }
.#{$fa-css-prefix}-ticket:before { content: fa-icon($fa-var-ticket); }
.#{$fa-css-prefix}-minus-square:before { content: fa-icon($fa-var-minus-square); }
.#{$fa-css-prefix}-minus-square-o:before { content: fa-icon($fa-var-minus-square-o); }
.#{$fa-css-prefix}-level-up:before { content: fa-icon($fa-var-level-up); }
.#{$fa-css-prefix}-level-down:before { content: fa-icon($fa-var-level-down); }
.#{$fa-css-prefix}-check-square:before { content: fa-icon($fa-var-check-square); }
.#{$fa-css-prefix}-pencil-square:before { content: fa-icon($fa-var-pencil-square); }
.#{$fa-css-prefix}-external-link-square:before { content: fa-icon($fa-var-external-link-square); }
.#{$fa-css-prefix}-share-square:before { content: fa-icon($fa-var-share-square); }
.#{$fa-css-prefix}-compass:before { content: fa-icon($fa-var-compass); }
.#{$fa-css-prefix}-toggle-down:before,
.#{$fa-css-prefix}-caret-square-o-down:before { content: fa-icon($fa-var-caret-square-o-down); }
.#{$fa-css-prefix}-toggle-up:before,
.#{$fa-css-prefix}-caret-square-o-up:before { content: fa-icon($fa-var-caret-square-o-up); }
.#{$fa-css-prefix}-toggle-right:before,
.#{$fa-css-prefix}-caret-square-o-right:before { content: fa-icon($fa-var-caret-square-o-right); }
.#{$fa-css-prefix}-euro:before,
.#{$fa-css-prefix}-eur:before { content: fa-icon($fa-var-eur); }
.#{$fa-css-prefix}-gbp:before { content: fa-icon($fa-var-gbp); }
.#{$fa-css-prefix}-dollar:before,
.#{$fa-css-prefix}-usd:before { content: fa-icon($fa-var-usd); }
.#{$fa-css-prefix}-file:before { content: fa-icon($fa-var-file); }
.#{$fa-css-prefix}-file-text:before { content: fa-icon($fa-var-file-text); }
.#{$fa-css-prefix}-sort-alpha-asc:before { content: fa-icon($fa-var-sort-alpha-asc); }
.#{$fa-css-prefix}-sort-alpha-desc:before { content: fa-icon($fa-var-sort-alpha-desc); }
.#{$fa-css-prefix}-sort-amount-asc:before { content: fa-icon($fa-var-sort-amount-asc); }
.#{$fa-css-prefix}-sort-amount-desc:before { content: fa-icon($fa-var-sort-amount-desc); }
.#{$fa-css-prefix}-sort-numeric-asc:before { content: fa-icon($fa-var-sort-numeric-asc); }
.#{$fa-css-prefix}-sort-numeric-desc:before { content: fa-icon($fa-var-sort-numeric-desc); }
.#{$fa-css-prefix}-thumbs-up:before { content: fa-icon($fa-var-thumbs-up); }
.#{$fa-css-prefix}-thumbs-down:before { content: fa-icon($fa-var-thumbs-down); }
.#{$fa-css-prefix}-youtube-square:before { content: fa-icon($fa-var-youtube-square); }
.#{$fa-css-prefix}-youtube:before { content: fa-icon($fa-var-youtube); }
.#{$fa-css-prefix}-youtube-play:before { content: fa-icon($fa-var-youtube-play); }
.#{$fa-css-prefix}-long-arrow-down:before { content: fa-icon($fa-var-long-arrow-down); }
.#{$fa-css-prefix}-long-arrow-up:before { content: fa-icon($fa-var-long-arrow-up); }
.#{$fa-css-prefix}-long-arrow-left:before { content: fa-icon($fa-var-long-arrow-left); }
.#{$fa-css-prefix}-long-arrow-right:before { content: fa-icon($fa-var-long-arrow-right); }
.#{$fa-css-prefix}-apple:before { content: fa-icon($fa-var-apple); }
.#{$fa-css-prefix}-windows:before { content: fa-icon($fa-var-windows); }
.#{$fa-css-prefix}-android:before { content: fa-icon($fa-var-android); }
.#{$fa-css-prefix}-linux:before { content: fa-icon($fa-var-linux); }
.#{$fa-css-prefix}-female:before { content: fa-icon($fa-var-female); }
.#{$fa-css-prefix}-male:before { content: fa-icon($fa-var-male); }
.#{$fa-css-prefix}-sun-o:before { content: fa-icon($fa-var-sun-o); }
.#{$fa-css-prefix}-moon-o:before { content: fa-icon($fa-var-moon-o); }
.#{$fa-css-prefix}-archive:before { content: fa-icon($fa-var-archive); }
.#{$fa-css-prefix}-bug:before { content: fa-icon($fa-var-bug); }
.#{$fa-css-prefix}-arrow-circle-o-right:before { content: fa-icon($fa-var-arrow-circle-o-right); }
.#{$fa-css-prefix}-arrow-circle-o-left:before { content: fa-icon($fa-var-arrow-circle-o-left); }
.#{$fa-css-prefix}-toggle-left:before,
.#{$fa-css-prefix}-caret-square-o-left:before { content: fa-icon($fa-var-caret-square-o-left); }
.#{$fa-css-prefix}-dot-circle-o:before { content: fa-icon($fa-var-dot-circle-o); }
.#{$fa-css-prefix}-wheelchair:before { content: fa-icon($fa-var-wheelchair); }
.#{$fa-css-prefix}-try:before { content: fa-icon($fa-var-try); }
.#{$fa-css-prefix}-plus-square-o:before { content: fa-icon($fa-var-plus-square-o); }
.#{$fa-css-prefix}-space-shuttle:before { content: fa-icon($fa-var-space-shuttle); }
.#{$fa-css-prefix}-envelope-square:before { content: fa-icon($fa-var-envelope-square); }
.#{$fa-css-prefix}-openid:before { content: fa-icon($fa-var-openid); }
.#{$fa-css-prefix}-institution:before,
.#{$fa-css-prefix}-bank:before,
.#{$fa-css-prefix}-university:before { content: fa-icon($fa-var-university); }
.#{$fa-css-prefix}-mortar-board:before,
.#{$fa-css-prefix}-graduation-cap:before { content: fa-icon($fa-var-graduation-cap); }
.#{$fa-css-prefix}-google:before { content: fa-icon($fa-var-google); }
.#{$fa-css-prefix}-language:before { content: fa-icon($fa-var-language); }
.#{$fa-css-prefix}-building:before { content: fa-icon($fa-var-building); }
.#{$fa-css-prefix}-child:before { content: fa-icon($fa-var-child); }
.#{$fa-css-prefix}-paw:before { content: fa-icon($fa-var-paw); }
.#{$fa-css-prefix}-spoon:before { content: fa-icon($fa-var-spoon); }
.#{$fa-css-prefix}-cube:before { content: fa-icon($fa-var-cube); }
.#{$fa-css-prefix}-cubes:before { content: fa-icon($fa-var-cubes); }
.#{$fa-css-prefix}-recycle:before { content: fa-icon($fa-var-recycle); }
.#{$fa-css-prefix}-automobile:before,
.#{$fa-css-prefix}-car:before { content: fa-icon($fa-var-car); }
.#{$fa-css-prefix}-cab:before,
.#{$fa-css-prefix}-taxi:before { content: fa-icon($fa-var-taxi); }
.#{$fa-css-prefix}-tree:before { content: fa-icon($fa-var-tree); }
.#{$fa-css-prefix}-database:before { content: fa-icon($fa-var-database); }
.#{$fa-css-prefix}-file-pdf-o:before { content: fa-icon($fa-var-file-pdf-o); }
.#{$fa-css-prefix}-file-word-o:before { content: fa-icon($fa-var-file-word-o); }
.#{$fa-css-prefix}-file-excel-o:before { content: fa-icon($fa-var-file-excel-o); }
.#{$fa-css-prefix}-file-powerpoint-o:before { content: fa-icon($fa-var-file-powerpoint-o); }
.#{$fa-css-prefix}-file-photo-o:before,
.#{$fa-css-prefix}-file-picture-o:before,
.#{$fa-css-prefix}-file-image-o:before { content: fa-icon($fa-var-file-image-o); }
.#{$fa-css-prefix}-file-zip-o:before,
.#{$fa-css-prefix}-file-archive-o:before { content: fa-icon($fa-var-file-archive-o); }
.#{$fa-css-prefix}-file-sound-o:before,
.#{$fa-css-prefix}-file-audio-o:before { content: fa-icon($fa-var-file-audio-o); }
.#{$fa-css-prefix}-file-movie-o:before,
.#{$fa-css-prefix}-file-video-o:before { content: fa-icon($fa-var-file-video-o); }
.#{$fa-css-prefix}-file-code-o:before { content: fa-icon($fa-var-file-code-o); }
.#{$fa-css-prefix}-life-bouy:before,
.#{$fa-css-prefix}-life-buoy:before,
.#{$fa-css-prefix}-life-saver:before,
.#{$fa-css-prefix}-support:before,
.#{$fa-css-prefix}-life-ring:before { content: fa-icon($fa-var-life-ring); }
.#{$fa-css-prefix}-circle-o-notch:before { content: fa-icon($fa-var-circle-o-notch); }
.#{$fa-css-prefix}-send:before,
.#{$fa-css-prefix}-paper-plane:before { content: fa-icon($fa-var-paper-plane); }
.#{$fa-css-prefix}-send-o:before,
.#{$fa-css-prefix}-paper-plane-o:before { content: fa-icon($fa-var-paper-plane-o); }
.#{$fa-css-prefix}-history:before { content: fa-icon($fa-var-history); }
.#{$fa-css-prefix}-circle-thin:before { content: fa-icon($fa-var-circle-thin); }
.#{$fa-css-prefix}-header:before { content: fa-icon($fa-var-header); }
.#{$fa-css-prefix}-paragraph:before { content: fa-icon($fa-var-paragraph); }
.#{$fa-css-prefix}-sliders:before { content: fa-icon($fa-var-sliders); }
.#{$fa-css-prefix}-share-alt:before { content: fa-icon($fa-var-share-alt); }
.#{$fa-css-prefix}-share-alt-square:before { content: fa-icon($fa-var-share-alt-square); }
.#{$fa-css-prefix}-bomb:before { content: fa-icon($fa-var-bomb); }
.#{$fa-css-prefix}-soccer-ball-o:before,
.#{$fa-css-prefix}-futbol-o:before { content: fa-icon($fa-var-futbol-o); }
.#{$fa-css-prefix}-tty:before { content: fa-icon($fa-var-tty); }
.#{$fa-css-prefix}-binoculars:before { content: fa-icon($fa-var-binoculars); }
.#{$fa-css-prefix}-plug:before { content: fa-icon($fa-var-plug); }
.#{$fa-css-prefix}-newspaper-o:before { content: fa-icon($fa-var-newspaper-o); }
.#{$fa-css-prefix}-wifi:before { content: fa-icon($fa-var-wifi); }
.#{$fa-css-prefix}-calculator:before { content: fa-icon($fa-var-calculator); }
.#{$fa-css-prefix}-paypal:before { content: fa-icon($fa-var-paypal); }
.#{$fa-css-prefix}-google-wallet:before { content: fa-icon($fa-var-google-wallet); }
.#{$fa-css-prefix}-cc-visa:before { content: fa-icon($fa-var-cc-visa); }
.#{$fa-css-prefix}-cc-mastercard:before { content: fa-icon($fa-var-cc-mastercard); }
.#{$fa-css-prefix}-cc-amex:before { content: fa-icon($fa-var-cc-amex); }
.#{$fa-css-prefix}-cc-paypal:before { content: fa-icon($fa-var-cc-paypal); }
.#{$fa-css-prefix}-bell-slash:before { content: fa-icon($fa-var-bell-slash); }
.#{$fa-css-prefix}-bell-slash-o:before { content: fa-icon($fa-var-bell-slash-o); }
.#{$fa-css-prefix}-trash:before { content: fa-icon($fa-var-trash); }
.#{$fa-css-prefix}-copyright:before { content: fa-icon($fa-var-copyright); }
.#{$fa-css-prefix}-at:before { content: fa-icon($fa-var-at); }
.#{$fa-css-prefix}-eyedropper:before { content: fa-icon($fa-var-eyedropper); }
.#{$fa-css-prefix}-paint-brush:before { content: fa-icon($fa-var-paint-brush); }
.#{$fa-css-prefix}-birthday-cake:before { content: fa-icon($fa-var-birthday-cake); }
.#{$fa-css-prefix}-area-chart:before { content: fa-icon($fa-var-area-chart); }
.#{$fa-css-prefix}-pie-chart:before { content: fa-icon($fa-var-pie-chart); }
.#{$fa-css-prefix}-line-chart:before { content: fa-icon($fa-var-line-chart); }
.#{$fa-css-prefix}-toggle-off:before { content: fa-icon($fa-var-toggle-off); }
.#{$fa-css-prefix}-toggle-on:before { content: fa-icon($fa-var-toggle-on); }
.#{$fa-css-prefix}-bicycle:before { content: fa-icon($fa-var-bicycle); }
.#{$fa-css-prefix}-bus:before { content: fa-icon($fa-var-bus); }
.#{$fa-css-prefix}-cc:before { content: fa-icon($fa-var-cc); }
.#{$fa-css-prefix}-cart-plus:before { content: fa-icon($fa-var-cart-plus); }
.#{$fa-css-prefix}-cart-arrow-down:before { content: fa-icon($fa-var-cart-arrow-down); }
.#{$fa-css-prefix}-diamond:before { content: fa-icon($fa-var-diamond); }
.#{$fa-css-prefix}-ship:before { content: fa-icon($fa-var-ship); }
.#{$fa-css-prefix}-user-secret:before { content: fa-icon($fa-var-user-secret); }
.#{$fa-css-prefix}-motorcycle:before { content: fa-icon($fa-var-motorcycle); }
.#{$fa-css-prefix}-street-view:before { content: fa-icon($fa-var-street-view); }
.#{$fa-css-prefix}-heartbeat:before { content: fa-icon($fa-var-heartbeat); }
.#{$fa-css-prefix}-whatsapp:before { content: fa-icon($fa-var-whatsapp); }
.#{$fa-css-prefix}-server:before { content: fa-icon($fa-var-server); }
.#{$fa-css-prefix}-user-plus:before { content: fa-icon($fa-var-user-plus); }
.#{$fa-css-prefix}-user-times:before { content: fa-icon($fa-var-user-times); }
.#{$fa-css-prefix}-hotel:before,
.#{$fa-css-prefix}-bed:before { content: fa-icon($fa-var-bed); }
.#{$fa-css-prefix}-train:before { content: fa-icon($fa-var-train); }
.#{$fa-css-prefix}-subway:before { content: fa-icon($fa-var-subway); }
.#{$fa-css-prefix}-expeditedssl:before { content: fa-icon($fa-var-expeditedssl); }
.#{$fa-css-prefix}-battery-4:before,
.#{$fa-css-prefix}-battery:before,
.#{$fa-css-prefix}-battery-full:before { content: fa-icon($fa-var-battery-full); }
.#{$fa-css-prefix}-battery-3:before,
.#{$fa-css-prefix}-battery-three-quarters:before { content: fa-icon($fa-var-battery-three-quarters); }
.#{$fa-css-prefix}-battery-2:before,
.#{$fa-css-prefix}-battery-half:before { content: fa-icon($fa-var-battery-half); }
.#{$fa-css-prefix}-battery-1:before,
.#{$fa-css-prefix}-battery-quarter:before { content: fa-icon($fa-var-battery-quarter); }
.#{$fa-css-prefix}-battery-0:before,
.#{$fa-css-prefix}-battery-empty:before { content: fa-icon($fa-var-battery-empty); }
.#{$fa-css-prefix}-mouse-pointer:before { content: fa-icon($fa-var-mouse-pointer); }
.#{$fa-css-prefix}-i-cursor:before { content: fa-icon($fa-var-i-cursor); }
.#{$fa-css-prefix}-object-group:before { content: fa-icon($fa-var-object-group); }
.#{$fa-css-prefix}-object-ungroup:before { content: fa-icon($fa-var-object-ungroup); }
.#{$fa-css-prefix}-sticky-note:before { content: fa-icon($fa-var-sticky-note); }
.#{$fa-css-prefix}-sticky-note-o:before { content: fa-icon($fa-var-sticky-note-o); }
.#{$fa-css-prefix}-clone:before { content: fa-icon($fa-var-clone); }
.#{$fa-css-prefix}-balance-scale:before { content: fa-icon($fa-var-balance-scale); }
.#{$fa-css-prefix}-hourglass-o:before { content: fa-icon($fa-var-hourglass-o); }
.#{$fa-css-prefix}-hourglass-1:before,
.#{$fa-css-prefix}-hourglass-start:before { content: fa-icon($fa-var-hourglass-start); }
.#{$fa-css-prefix}-hourglass-2:before,
.#{$fa-css-prefix}-hourglass-half:before { content: fa-icon($fa-var-hourglass-half); }
.#{$fa-css-prefix}-hourglass-3:before,
.#{$fa-css-prefix}-hourglass-end:before { content: fa-icon($fa-var-hourglass-end); }
.#{$fa-css-prefix}-hourglass:before { content: fa-icon($fa-var-hourglass); }
.#{$fa-css-prefix}-hand-grab-o:before,
.#{$fa-css-prefix}-hand-rock-o:before { content: fa-icon($fa-var-hand-rock-o); }
.#{$fa-css-prefix}-hand-stop-o:before,
.#{$fa-css-prefix}-hand-paper-o:before { content: fa-icon($fa-var-hand-paper-o); }
.#{$fa-css-prefix}-hand-scissors-o:before { content: fa-icon($fa-var-hand-scissors-o); }
.#{$fa-css-prefix}-hand-lizard-o:before { content: fa-icon($fa-var-hand-lizard-o); }
.#{$fa-css-prefix}-hand-spock-o:before { content: fa-icon($fa-var-hand-spock-o); }
.#{$fa-css-prefix}-hand-pointer-o:before { content: fa-icon($fa-var-hand-pointer-o); }
.#{$fa-css-prefix}-hand-peace-o:before { content: fa-icon($fa-var-hand-peace-o); }
.#{$fa-css-prefix}-trademark:before { content: fa-icon($fa-var-trademark); }
.#{$fa-css-prefix}-registered:before { content: fa-icon($fa-var-registered); }
.#{$fa-css-prefix}-creative-commons:before { content: fa-icon($fa-var-creative-commons); }
.#{$fa-css-prefix}-chrome:before { content: fa-icon($fa-var-chrome); }
.#{$fa-css-prefix}-firefox:before { content: fa-icon($fa-var-firefox); }
.#{$fa-css-prefix}-tv:before,
.#{$fa-css-prefix}-television:before { content: fa-icon($fa-var-television); }
.#{$fa-css-prefix}-calendar-plus-o:before { content: fa-icon($fa-var-calendar-plus-o); }
.#{$fa-css-prefix}-calendar-minus-o:before { content: fa-icon($fa-var-calendar-minus-o); }
.#{$fa-css-prefix}-calendar-times-o:before { content: fa-icon($fa-var-calendar-times-o); }
.#{$fa-css-prefix}-calendar-check-o:before { content: fa-icon($fa-var-calendar-check-o); }
.#{$fa-css-prefix}-industry:before { content: fa-icon($fa-var-industry); }
.#{$fa-css-prefix}-map-pin:before { content: fa-icon($fa-var-map-pin); }
.#{$fa-css-prefix}-map-signs:before { content: fa-icon($fa-var-map-signs); }
.#{$fa-css-prefix}-map-o:before { content: fa-icon($fa-var-map-o); }
.#{$fa-css-prefix}-map:before { content: fa-icon($fa-var-map); }
.#{$fa-css-prefix}-commenting:before { content: fa-icon($fa-var-commenting); }
.#{$fa-css-prefix}-commenting-o:before { content: fa-icon($fa-var-commenting-o); }
.#{$fa-css-prefix}-black-tie:before { content: fa-icon($fa-var-black-tie); }
.#{$fa-css-prefix}-edge:before { content: fa-icon($fa-var-edge); }
.#{$fa-css-prefix}-credit-card-alt:before { content: fa-icon($fa-var-credit-card-alt); }
.#{$fa-css-prefix}-fort-awesome:before { content: fa-icon($fa-var-fort-awesome); }
.#{$fa-css-prefix}-usb:before { content: fa-icon($fa-var-usb); }
.#{$fa-css-prefix}-pause-circle:before { content: fa-icon($fa-var-pause-circle); }
.#{$fa-css-prefix}-pause-circle-o:before { content: fa-icon($fa-var-pause-circle-o); }
.#{$fa-css-prefix}-stop-circle:before { content: fa-icon($fa-var-stop-circle); }
.#{$fa-css-prefix}-stop-circle-o:before { content: fa-icon($fa-var-stop-circle-o); }
.#{$fa-css-prefix}-shopping-bag:before { content: fa-icon($fa-var-shopping-bag); }
.#{$fa-css-prefix}-shopping-basket:before { content: fa-icon($fa-var-shopping-basket); }
.#{$fa-css-prefix}-hashtag:before { content: fa-icon($fa-var-hashtag); }
.#{$fa-css-prefix}-bluetooth:before { content: fa-icon($fa-var-bluetooth); }
.#{$fa-css-prefix}-bluetooth-b:before { content: fa-icon($fa-var-bluetooth-b); }
.#{$fa-css-prefix}-percent:before { content: fa-icon($fa-var-percent); }
.#{$fa-css-prefix}-universal-access:before { content: fa-icon($fa-var-universal-access); }
.#{$fa-css-prefix}-wheelchair-alt:before { content: fa-icon($fa-var-wheelchair-alt); }
.#{$fa-css-prefix}-question-circle-o:before { content: fa-icon($fa-var-question-circle-o); }
.#{$fa-css-prefix}-blind:before { content: fa-icon($fa-var-blind); }
.#{$fa-css-prefix}-audio-description:before { content: fa-icon($fa-var-audio-description); }
.#{$fa-css-prefix}-volume-control-phone:before { content: fa-icon($fa-var-volume-control-phone); }
.#{$fa-css-prefix}-fa:before,
.#{$fa-css-prefix}-handshake-o:before { content: fa-icon($fa-var-handshake-o); }
.#{$fa-css-prefix}-envelope-open:before { content: fa-icon($fa-var-envelope-open); }
.#{$fa-css-prefix}-envelope-open-o:before { content: fa-icon($fa-var-envelope-open-o); }
.#{$fa-css-prefix}-address-book:before { content: fa-icon($fa-var-address-book); }
.#{$fa-css-prefix}-address-book-o:before { content: fa-icon($fa-var-address-book-o); }
.#{$fa-css-prefix}-vcard:before,
.#{$fa-css-prefix}-address-card:before { content: fa-icon($fa-var-address-card); }
.#{$fa-css-prefix}-vcard-o:before,
.#{$fa-css-prefix}-address-card-o:before { content: fa-icon($fa-var-address-card-o); }
.#{$fa-css-prefix}-user-circle:before { content: fa-icon($fa-var-user-circle); }
.#{$fa-css-prefix}-user-circle-o:before { content: fa-icon($fa-var-user-circle-o); }
.#{$fa-css-prefix}-user-o:before { content: fa-icon($fa-var-user-o); }
.#{$fa-css-prefix}-id-badge:before { content: fa-icon($fa-var-id-badge); }
.#{$fa-css-prefix}-drivers-license:before,
.#{$fa-css-prefix}-id-card:before { content: fa-icon($fa-var-id-card); }
.#{$fa-css-prefix}-drivers-license-o:before,
.#{$fa-css-prefix}-id-card-o:before { content: fa-icon($fa-var-id-card-o); }
.#{$fa-css-prefix}-window-maximize:before { content: fa-icon($fa-var-window-maximize); }
.#{$fa-css-prefix}-window-minimize:before { content: fa-icon($fa-var-window-minimize); }
.#{$fa-css-prefix}-window-restore:before { content: fa-icon($fa-var-window-restore); }
.#{$fa-css-prefix}-times-rectangle:before,
.#{$fa-css-prefix}-window-close:before { content: fa-icon($fa-var-window-close); }
.#{$fa-css-prefix}-times-rectangle-o:before,
.#{$fa-css-prefix}-window-close-o:before { content: fa-icon($fa-var-window-close-o); }

